/**
 * @generated SignedSource<<f0d7da7772b944997814776e0d5f516a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TournamentUserRoleEnum = "ADMIN" | "INSPECTOR" | "MANAGER" | "PHOTO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TournamentCard_tournament$data = {
  readonly address: {
    readonly " $fragmentSpreads": FragmentRefs<"CountryFlagName_address" | "TournamentCard_address">;
  } | null | undefined;
  readonly cover: string | null | undefined;
  readonly date: string;
  readonly entityId: number;
  readonly isActivated: boolean;
  readonly isOwner: boolean;
  readonly myRole: TournamentUserRoleEnum | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "TournamentCard_tournament";
};
export type TournamentCard_tournament$key = {
  readonly " $data"?: TournamentCard_tournament$data;
  readonly " $fragmentSpreads": FragmentRefs<"TournamentCard_tournament">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TournamentCard_tournament",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CountryFlagName_address"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TournamentCard_address"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActivated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "myRole",
      "storageKey": null
    }
  ],
  "type": "Tournament",
  "abstractKey": null
};

(node as any).hash = "821ab6cfa19a4742132449c88a3f2ab4";

export default node;
