import { FC } from 'react';
import { usePathname } from 'next/navigation';
import { match } from 'path-to-regexp';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { Box, Tab, Tabs } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const PastFutureTournamentTabs: FC = () => {
    const theme = useTheme();
    const breakpointMatches = useMediaQuery(theme.breakpoints.down('sm'));

    const pastTournamentsRootMatcher = match('/past-tournaments/', { end: false });
    const path = usePathname();
    const isPastTournamentsRootRoute = !!pastTournamentsRootMatcher(path);

    return (
        <Box borderBottom={1} borderColor="divider" my={3}>
            <Tabs
                value={isPastTournamentsRootRoute ? 1 : 0}
                onChange={() => {}}
                centered={!breakpointMatches}
                variant={breakpointMatches ? 'scrollable' : 'standard'}
            >
                <Tab
                    component={Link}
                    href="/"
                    label={<FormattedMessage defaultMessage="Upcoming Tournaments" id="Zm6CwX" />}
                />
                <Tab
                    component={Link}
                    href="/past-tournaments/"
                    label={<FormattedMessage defaultMessage="Past Tournaments" id="/bPKg1" />}
                />
            </Tabs>
        </Box>
    );
};

export default PastFutureTournamentTabs;
