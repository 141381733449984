/**
 * @generated SignedSource<<504bfd3e50071e31614fce652606f538>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TournamentCard_address$data = {
  readonly country: {
    readonly code: string;
    readonly name: string;
  };
};
export type TournamentCard_address$key = {
  readonly " $data"?: TournamentCard_address$data;
  readonly " $fragmentSpreads": FragmentRefs<"TournamentCard_address">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "TournamentCard_address",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "country",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Address",
  "abstractKey": null
};

(node as any).hash = "8b3fcc87854cb006b968f9bbad4e6c18";

export default node;
