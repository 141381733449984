import { styled } from '@mui/material/styles';

export const CardInfo = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const Address = styled('div')(({ theme }) => ({
    color: theme.palette.text.disabled,
}));
