import { FC } from 'react';
import Link from 'next/link';
import { graphql, useLazyLoadQuery } from 'react-relay';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';

import { filterNullNodes } from '@ktech/relay';
import TournamentCard from '../TournamentCard';
import NextPagination from '../../general/NextPagination';

import { TournamentsPublicLayoutQuery as TournamentsPublicLayoutQueryType } from './__generated__/TournamentsPublicLayoutQuery.graphql';
import { IconButton, MenuItem, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import FormikSelect from '@ktech/components/src/FormikSelect';
import { usePathname, useRouter } from 'next/navigation';
export type { TournamentsPublicLayoutQueryType };

export const TournamentsPublicLayoutQuery = graphql`
    query TournamentsPublicLayoutQuery(
        $page: Int!
        $futureTournaments: Boolean!
        $orderDirection: OrderDirectionEnum!
        $first: Int!
        $filter: TournamentQueryFilterInput
    ) {
        tournamentCountries(futureTournaments: $futureTournaments) {
            code
            name
        }
        tournaments(
            futureTournaments: $futureTournaments
            orderBy: { date: $orderDirection }
            first: $first
            page: $page
            filter: $filter
        ) {
            edges {
                cursor
                node {
                    ...TournamentCard_tournament
                    entityId
                }
            }
            numberOfPages
        }
    }
`;

type Props = {
    page: number;
    first: number;
    futureTournaments: boolean;
    orderDirection: 'ASC' | 'DESC';
    search?: string;
    countryCode?: string;
};

const TournamentsPublicLayout: FC<Props> = ({
    first,
    page,
    futureTournaments,
    orderDirection,
    search = '',
    countryCode = '',
}) => {
    const path = usePathname();
    const router = useRouter();

    const query = useLazyLoadQuery<TournamentsPublicLayoutQueryType>(
        TournamentsPublicLayoutQuery,
        {
            page,
            first,
            futureTournaments,
            orderDirection,
            filter: {
                name: search ? { _like: search } : null,
                address: countryCode ? { countryCode: { _eq: countryCode } } : null,
            },
        },
        { fetchPolicy: 'store-or-network' }
    );

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    search,
                    countryCode,
                }}
                onSubmit={values => {
                    const searchParams = new URLSearchParams();
                    if (values.search) {
                        searchParams.set('search', values.search);
                    }

                    if (values.countryCode) {
                        searchParams.set('countryCode', values.countryCode);
                    }
                    router.push(`${path}?${searchParams.toString()}`);
                }}
            >
                {({ setFieldValue, submitForm }) => (
                    <Form>
                        <Stack direction="row" spacing={2} justifyContent="center" mb={3}>
                            <Field
                                as={TextField}
                                label={<FormattedMessage defaultMessage="Search" id="xmcVZ0" />}
                                name="search"
                                sx={{ mb: 2 }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton size="small" type="submit">
                                            <SearchIcon />
                                        </IconButton>
                                    ),
                                }}
                                fullWidth
                            />

                            <FormikSelect
                                name="countryCode"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                selectProps={{
                                    onChange: e => {
                                        setFieldValue('countryCode', e.target.value);
                                        submitForm();
                                    },
                                }}
                                label={<FormattedMessage defaultMessage="Country" id="vONi+O" />}
                            >
                                <MenuItem value="">-</MenuItem>
                                {query.tournamentCountries.map(({ code, name }) => (
                                    <MenuItem key={code} value={code}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </FormikSelect>
                        </Stack>
                    </Form>
                )}
            </Formik>

            <Grid
                container
                spacing={2}
                columns={
                    futureTournaments
                        ? {
                              xs: 1,
                              sm: 2,
                              md: 3,
                          }
                        : {
                              xs: 1,
                              sm: 2,
                              md: 4,
                          }
                }
            >
                {query.tournaments.edges.filter(filterNullNodes).map(({ cursor, node }) => (
                    <Grid item xs={1} display="flex" key={cursor}>
                        <ButtonBase
                            sx={{ width: '100%', alignItems: 'stretch' }}
                            LinkComponent={Link}
                            href={`/tournament/${node.entityId}`}
                        >
                            <TournamentCard tournament={node} />
                        </ButtonBase>
                    </Grid>
                ))}
            </Grid>

            <NextPagination
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                }}
                queryName="page"
                count={query.tournaments.numberOfPages}
                siblingCount={5}
                page={page}
            />
        </>
    );
};

export default TournamentsPublicLayout;
