import Link from 'next/link';
import { FC } from 'react';
import Pagination, { PaginationProps } from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { usePathname, useSearchParams } from 'next/navigation';

type Props = { queryName?: string } & Omit<PaginationProps, 'renderItem'>;

const NextPagination: FC<Props> = ({ queryName = 'page', ...props }) => {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const searchParamsEntries: Record<string, string> = {};
    [...searchParams.entries()].forEach(([key, value]) => {
        searchParamsEntries[key] = value;
    });

    if (props.count === 1) {
        return null;
    }

    return (
        <Pagination
            {...props}
            renderItem={item =>
                item.disabled ? null : (
                    <Link
                        passHref
                        href={{
                            pathname,
                            query: { ...searchParamsEntries, [queryName]: item.page },
                        }}
                    >
                        <PaginationItem {...item} />
                    </Link>
                )
            }
        />
    );
};

export default NextPagination;
