import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { CountryFlagName_address$key } from './__generated__/CountryFlagName_address.graphql';

type Props =
    | {
          address: CountryFlagName_address$key | null;
          code?: undefined;
          name?: undefined;
      }
    | {
          address?: undefined;
          code: string;
          name: string;
      };

const CountryFlagName: FC<Props> = ({ address: addressRef, code, name }) => {
    const address = useFragment(
        graphql`
            fragment CountryFlagName_address on Address {
                country {
                    code
                    name
                }
            }
        `,
        addressRef || null
    );

    if (address) {
        return (
            <>
                <Box mr={1} component={ReactCountryFlag} svg countryCode={address.country.code} />
                {address.country.name}
            </>
        );
    }

    return (
        <>
            <Box mr={1} component={ReactCountryFlag} svg countryCode={code || ''} />
            {name}
        </>
    );
};

export default CountryFlagName;
