import { FC, ReactNode } from 'react';
import { FormattedDate } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import CountryFlagName from '@ktech/components/src/CountryFlagName';
import PhotoPreviewCardMedia from '@ktech/components/src/PhotoPreviewCardMedia';
import { Address, CardInfo } from './TournamentCard.styled';

import {
    TournamentCard_tournament$data,
    TournamentCard_tournament$key,
} from './__generated__/TournamentCard_tournament.graphql';

export type ActionsNodePayload = TournamentCard_tournament$data;

type Props = {
    children?: ReactNode;
    tournament: TournamentCard_tournament$key;
    actionsNode?: (tournament: ActionsNodePayload) => ReactNode;
};

graphql`
    fragment TournamentCard_address on Address @relay(mask: false) {
        country {
            code
            name
        }
    }
`;

const TournamentCard: FC<Props> = ({ tournament: tournamentRef, children, actionsNode }) => {
    const tournament = useFragment(
        graphql`
            fragment TournamentCard_tournament on Tournament {
                entityId
                name
                cover
                date
                address {
                    ...CountryFlagName_address
                    ...TournamentCard_address
                }
                isActivated
                isOwner
                myRole
            }
        `,
        tournamentRef
    );

    return (
        <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <PhotoPreviewCardMedia
                image={tournament.cover}
                alternativeImage="/kumite-technology-logo-sign.png"
                alt={tournament.name}
            />
            <CardContent
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Typography gutterBottom variant="body1">
                    {tournament.name}
                </Typography>
                <CardInfo>
                    <Address>
                        {tournament.address && <CountryFlagName address={tournament.address} />}
                    </Address>
                    <Box display="flex" alignItems="center">
                        <CalendarMonth sx={{ mr: 1 }} />
                        <strong>
                            <FormattedDate value={tournament.date} />
                        </strong>
                    </Box>
                </CardInfo>
            </CardContent>
            {children}
            {actionsNode?.(tournament)}
        </Card>
    );
};

export default TournamentCard;
