/**
 * @generated SignedSource<<2a27a4e83a5b30181d15374fc4c034a7>>
 * @relayHash 0da40647837af545697959cbd5a5ef5a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0da40647837af545697959cbd5a5ef5a

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDirectionEnum = "ASC" | "DESC" | "%future added value";
export type TournamentQueryFilterInput = {
  _and?: ReadonlyArray<TournamentFilterInput> | null | undefined;
  _or?: ReadonlyArray<TournamentFilterInput> | null | undefined;
  address?: AddressFilterInput | null | undefined;
  date?: StringComparisonExp | null | undefined;
  entityId?: IntComparisonExp | null | undefined;
  name?: StringComparisonExp | null | undefined;
};
export type TournamentFilterInput = {
  address?: AddressFilterInput | null | undefined;
  date?: StringComparisonExp | null | undefined;
  entityId?: IntComparisonExp | null | undefined;
  name?: StringComparisonExp | null | undefined;
};
export type AddressFilterInput = {
  countryCode?: StringComparisonExp | null | undefined;
  region?: StringComparisonExp | null | undefined;
};
export type StringComparisonExp = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _like?: string | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
  _nin?: ReadonlyArray<string> | null | undefined;
  _nlike?: string | null | undefined;
};
export type IntComparisonExp = {
  _eq?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _neq?: number | null | undefined;
  _nin?: ReadonlyArray<number> | null | undefined;
};
export type TournamentsPublicLayoutQuery$variables = {
  filter?: TournamentQueryFilterInput | null | undefined;
  first: number;
  futureTournaments: boolean;
  orderDirection: OrderDirectionEnum;
  page: number;
};
export type TournamentsPublicLayoutQuery$data = {
  readonly tournamentCountries: ReadonlyArray<{
    readonly code: string;
    readonly name: string;
  }>;
  readonly tournaments: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly entityId: number;
        readonly " $fragmentSpreads": FragmentRefs<"TournamentCard_tournament">;
      } | null | undefined;
    }>;
    readonly numberOfPages: number;
  };
};
export type TournamentsPublicLayoutQuery = {
  response: TournamentsPublicLayoutQuery$data;
  variables: TournamentsPublicLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "futureTournaments"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderDirection"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v5 = {
  "kind": "Variable",
  "name": "futureTournaments",
  "variableName": "futureTournaments"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": [
    (v5/*: any*/)
  ],
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "tournamentCountries",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v5/*: any*/),
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "orderDirection"
      }
    ],
    "kind": "ObjectValue",
    "name": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfPages",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TournamentsPublicLayoutQuery",
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "TournamentConnection",
        "kind": "LinkedField",
        "name": "tournaments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Tournament",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TournamentCard_tournament"
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TournamentsPublicLayoutQuery",
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "TournamentConnection",
        "kind": "LinkedField",
        "name": "tournaments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Tournament",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cover",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "address",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Country",
                        "kind": "LinkedField",
                        "name": "country",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActivated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isOwner",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "myRole",
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "0da40647837af545697959cbd5a5ef5a",
    "metadata": {},
    "name": "TournamentsPublicLayoutQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "49bfe7ced17cd78470a43b20b6caa2ce";

export default node;
